.button-bg {
  background: #2AB5DD;
  color: #fff;
}

.goBackBtn {
  border-color: transparent;
  padding: 10px 16px;
  font-size: 16px;
  border-radius: 8px;
  box-shadow: none;
}